<template>
  <div class="typedetails">
    <div class="common_page_title">
      <div class="guwidth">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/falvzixun' }">法律咨询主页</el-breadcrumb-item>
          <el-breadcrumb-item>问题类型</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <!-- 内容 -->
    <div class="typedetailsmain base_w flex">
      <!-- 左竖列 -->
      <div class="common_index_leftwidth">
        <div class="typeqb flexbox">
          <span  @click.stop="handleClick(-1)" :class="[activeTabName == -1 ? 'active' : '']">全部类型</span>
          <span 
          v-for="(item,i) in lawKnowledgelist" :key="i" 
          :class="[activeTabName == i ? 'active' : '']"
          @click.stop="handleClick(i,item)">
          {{item.name}}</span>
        </div>
        <wenda ref="newwenda" :paging="true" :type="3" :wendalistArr="wentypedalist"></wenda>

        <div class="fenyebox">
            <el-pagination
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-size="pagination.size"
              layout="total, prev, pager, next"
              :total="pagination.total">
            </el-pagination>
          </div>

          <NoData v-if="wentypedalist.length <= 0"></NoData>
      </div>
      <!-- 右竖列 -->
      <div class="common_index_rightwidth">
        <!-- 在线法律咨询 -->
        <div class="zxflzx flex_column_center">
          <p class="c1">在线法律咨询</p>
          <p class="c2"><span>1</span>分钟提问，<span>30</span>秒匹配律师</p>
          <div class="imgbox1">
              <img src="@/assets/img/ls.png" alt="" class="img1">
              <img src="@/assets/img/ls.png" alt="" class="img2">
              <img src="@/assets/img/ls.png" alt="" class="img3">
              <p>当前 <span> 2399  </span> 名律师在线</p>
          </div>
          <div class="btnbox" @click.stop="tourl('/toask')">立即提问</div>
        </div>
        <!-- 热门律师 -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">热门律师</span>
            <div class="righttext" @click.stop="tourl('/lvlist')">
                更多
                <img src="@/assets/img/right.png" alt="" class="iconimg">
            </div>
          </div>
          <hotls :list="hotlawlist"></hotls>
        </div>

        <!-- 法律资讯 -->
        <div class="flzx">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="flzxbox">
            <news :type="2" :list="informationlist"></news>
          </div>

        </div>
      </div>
    </div>
  

  </div>
</template>

<script>
import wenda  from '@/components/wenda'
import hotls  from '@/components/hotls'
import news  from '@/components/news'
import NoData  from '@/components/NoData'

import apiUrl from '@/api/main'

export default {
  components: {wenda,hotls,news,NoData},
  data() {
      return {
        activeTabName:-1,
        typename:'', // 分类名称
        lawKnowledgelist:[], // 类型列表
        wentypedalist:[], // 咨询列表
        hotlawlist:[], // 热门律师
        pagination:{
          size: 7, // 每页几条
          currentPage: 1,
          total: 0
        },
        informationlist:[], // 法律资讯
      };
  },
  computed: {},
  watch: {},
  mounted() {
    // 获取全部分类咨询列表
    this.getlawKnowledge();
    this.gettypewendalist(); // 分类下咨询

    this.gethostlist(); // 热门律师
    this.getinformation(); // 法律资讯
    // 跳转回顶部
    document.documentElement.scrollTop = 0;

    // wendetails 问答详情中，更多相似问题进来
    if(this.$route.query.fenglei){
      this.typename = this.$route.query.fenglei;
    }
  },
  methods: {
     // 跳转
     tourl(url) {
        this.$router.push({
          path: url
        })
      },
      // 点击分类
      handleClick(index,item) {
        // 全部类型
        if(index == -1){
          this.activeTabName = -1;
          this.typename = ''
        } else {
          this.activeTabName = index;
          this.typename = item.name;
        }
        this.pagination.currentPage = 1;
        this.gettypewendalist();
      },
      
       // 获取全部分类咨询列表
       getlawKnowledge(){
        let data = {
          pId: 0,
          userId: localStorage.getItem('userId')
        }
        apiUrl.lsLawKnowledgelist(data).then((res) => {
            this.lawKnowledgelist = res.rows;

            if(this.lawKnowledgelist.length > 0){
              this.lawKnowledgelist.forEach((element,i) => {
                if(element.name == this.$route.query.fenglei){
                  this.activeTabName = i;
                  this.gettypewendalist();
                }
              });
            }
        })
      },
      // 获取分类下的 咨询列表
      gettypewendalist() {
        let data = {
          pageSize: this.pagination.size,
          pageNum: this.pagination.currentPage,
          fenglei: this.typename,
        }
        apiUrl.wendalist(data).then((res) => {
            this.wentypedalist = res.rows;
            this.pagination.total = res.total;
        })
      },
      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.gettypewendalist();
        // 跳转回顶部
        document.documentElement.scrollTop = 0;
      },
       // 获取 热门律师
       gethostlist() {
        let data = {
          pageSize: 3,
          pageNum: 1,
          tuijian: 1,
          orderByColumn: "haopinhlv",
          isAsc: "desc"
        }
        apiUrl.lsLawyerindex(data).then((res) => {
            res.rows.forEach((item,i) => {
              if(item.goodsIds){
                item.goodsIdsArr = item.goodsIds.split(',');
              }
            });

            this.hotlawlist = res.rows;
        })
      },
    // 获取法律资讯 
      getinformation(){
        let data = {
          userId:localStorage.getItem('userId'),
          pageSize: 3,
          pageNum: 1,
        }
        apiUrl.lsInformationlist(data).then((res) => {
           this.informationlist = res.rows;
        });
      },
  },

  }
</script>
<style lang='scss' scoped>
.typedetailsmain{
  padding-bottom: 67px;
  .common_index_leftwidth{
  
    .typeqb{
      flex-wrap: wrap;
      margin-top: 39px;
      border-bottom: 1px solid  #EEEEEE;
      // p{
      //   font-size: 18px;
      //   color: #333333;
      //   margin-right: 23px;
      //   margin-bottom: 39px;
      // }
      span{
        font-size: 18px;
        color: #333333;
        margin-right: 44px;
        cursor: pointer;
        margin-bottom: 39px;
      }
      span:hover{
        color: #305BFE;
      }
      span.active{
        background: #305BFE;
        border-radius: 18px;
        padding: 7px 16px;
        color: #fff;
      }
    }
  }
  .common_index_rightwidth{
    .zxflzx{
      background: url(../../assets/img/bg8.png) no-repeat;
      background-size: 100% 100%;
      width: 586px;
      height: 326px;
      margin-top: 33px;
      .c1{
          font-size: 28px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #3D4962;
          line-height: 31px;
          letter-spacing: 2px;
      }
      .c2{
        font-size: 22px;
        font-weight: 500;
        color: #3D4962;
        margin-top: 28px;
        span{
          color: #FF7900;
        }
      }
      .imgbox1{
        position: relative;
        width: 400px;
        margin-top: 40px;
        img{
          width: 36px;
          height: 36px;
          border-radius: 50%;
          position: absolute;
        }
        .img1{
          top: 0;
          left: 23%;
        }
        .img2{
          top: 0;
          left: 28%;
        }
        .img3{
          top: 0;
          left: 33%;
        }
        p{
          position: absolute;
          top: 0;
          left: 42%;
          display: inline-block;
          height: 27px;
          line-height: 27px;
          font-size: 16px;
          color: #60666F;
          span{
            color: #305BFE;
            margin: 0 3px;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .btnbox{
        width: 168px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
        margin-top: 80px;
        font-size: 18px;
      }
    }
    // 热门律师
    .rmls{
      margin-top: 33px;
      .common_title_box{
        margin-bottom: 16px;
      }
    }
    // 法律资讯
    .flzx{
      margin-top: 33px;
      .flzxbox{
        // width: 488px;
        width: 586px;
        background: #F8F8F8;
        border-radius: 12px;
        padding: 27px;
        margin-top: 16px;
      }
    }
  
  }





















}

</style>